import React from "react";
import useData from "@staticQueries/FooterQuery";
import { useAppState } from "@state";

import { Social, LoginModal } from "@molecules";
import { Container, Text, Button, Icon, EmbedCode } from "@atoms";
import { AppLink } from "@base";

const currentYear = new Date().getFullYear();

const hasWindow = typeof window !== "undefined";

const isSamePage = url => hasWindow && window.location.pathname.includes(url);

const scrollTo = target => {
  if (hasWindow) {
    document.getElementById(target).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const Footer = () => {
  const [, dispatch] = useAppState();

  const {
    heading,
    company,
    privacy,
    terms,
    headerLinks,
    phone,
    email,
    social,
    donate,
    embeds,
  } = useData();

  // this function splits headerLinks into two arrays
  // one with sublinks and one without
  const groupedLinks = headerLinks.reduce(
    (acc, item) => {
      item.sublinks.length ? acc[0].push(item) : acc[1].push(item);
      return acc;
    },
    [[], []]
  );

  const openModal = () =>
    dispatch({
      type: "openModal",
      title: "What kind of user are you?",
      description: `Login prompt.`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(() => <LoginModal />),
      hideButton: true,
    });

  return (
    <footer className="mt-10 max-w-7xl rounded-t-4xl bg-pink font-medium sm:mt-20 lg:mx-auto">
      <Container className="flex flex-col py-12 md:py-16">
        {/* heading */}
        <Text variant="h3" className="bold-white px-4 text-left sm:text-center">
          {heading}
        </Text>
        <div className="flex flex-wrap sm:mt-12 md:mt-16 md:flex-nowrap">
          {/* logo section */}
          <div className="mb-12 mt-8 flex max-w-[12rem] flex-shrink-0 flex-col items-start gap-4 px-4 md:mt-0">
            <AppLink to="/" className="block max-w-[10rem]">
              <Icon
                name="logoWhite"
                className="w-full !fill-white !text-white"
              />
            </AppLink>
            <Text variant="xs">{`&copy; ${currentYear} ${company.replace(
              /<[^>]+>/g,
              ""
            )}`}</Text>
            {privacy?.url && (
              <AppLink
                to={privacy.url}
                className="text-xs duration-200 hover:text-white"
              >
                {privacy.text || "Privacy Policy"}
              </AppLink>
            )}
            {terms?.url && (
              <AppLink
                to={terms.url}
                className="text-xs duration-200 hover:text-white"
              >
                {terms.text || "Terms of Service"}
              </AppLink>
            )}
          </div>
          {/* nav */}
          <nav className="flex w-full flex-wrap text-black md:pl-4 md:pr-4 lg:flex-nowrap lg:justify-between lg:pr-16 xl:justify-between">
            {/* links with sublinks */}
            {groupedLinks[0]?.map((group, i) => {
              return (
                <ul
                  className="group mb-6 flex w-full flex-col items-start gap-1.5 px-4 text-left sm:mb-12 sm:w-1/3 md:w-auto"
                  key={group.headLink.uid}
                >
                  <li>
                    <AppLink
                      to={group.headLink.url}
                      className="text-left font-bold duration-200 hover:text-white lg:text-sm"
                    >
                      {group.headLink.text}
                    </AppLink>
                    {group.headLink.url?.includes("contact") && (
                      <ul className="mt-8 flex flex-col space-y-4 text-sm lg:text-xxs">
                        <li>
                          <AppLink
                            to={phone.url}
                            className="duration-200 hover:text-white"
                          >
                            {phone.text}
                          </AppLink>
                        </li>
                        <li>
                          <AppLink
                            to={email.url}
                            className="duration-200 hover:text-white"
                          >
                            {email.text}
                          </AppLink>
                        </li>
                        <Social social={social} />
                      </ul>
                    )}
                  </li>
                  {/* map sublinks within group */}
                  {group.sublinks.map(subgroup => {
                    const { uid } = subgroup;
                    const { url, text } = subgroup.sublink;
                    const baseUrl = url.split("#")[0];
                    const anchor = url.split("#")[1];
                    return (
                      <li key={uid}>
                        <AppLink
                          to={isSamePage(baseUrl) && anchor ? null : url}
                          onClick={() => {
                            // scroll again when clicking the same anchor link on the same page
                            if (isSamePage(baseUrl)) {
                              scrollTo(anchor);
                            }
                          }}
                          className="text-xs duration-200 hover:text-white lg:text-xxs"
                        >
                          {text}
                        </AppLink>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
            {/* links without sublinks */}
            <ul className="group mb-12 flex w-1/2 flex-col items-start gap-1.5 px-4 text-left md:w-1/3 lg:w-1/5 xl:mb-0">
              {groupedLinks[1].map(group => {
                if (group.headLink.url === "LOGIN") {
                  return (
                    <AppLink
                      className="text-sm font-bold duration-200 hover:text-white"
                      onClick={openModal}
                    >
                      {group.headLink.text}
                    </AppLink>
                  );
                }
                return (
                  <li key={group.headLink.uid}>
                    <AppLink
                      to={group.headLink.url}
                      className="text-sm font-bold duration-200 hover:text-white"
                    >
                      {group.headLink.text}
                    </AppLink>
                  </li>
                );
              })}
              <li className="mt-6 flex flex-col gap-4 text-xs">
                {phone?.url && (
                  <AppLink
                    to={phone.url}
                    className="duration-200 hover:text-white"
                  >
                    {phone.text}
                  </AppLink>
                )}
                <AppLink
                  to={email.url}
                  className="duration-200 hover:text-white"
                >
                  {email.text}
                </AppLink>
                <Social social={social} />
              </li>
            </ul>
          </nav>
        </div>
        {/* donate info */}
        <div className="flex flex-wrap-reverse gap-2 px-4 pt-0 sm:flex-nowrap">
          {/* embeds */}
          {embeds?.map(embed => (
            <div key={embed?.uid} className="mt-8 sm:mt-0 sm:shrink-0">
              <EmbedCode html={embed?.html} />
            </div>
          ))}

          <div className="flex w-full max-w-md flex-col items-start space-y-4 sm:ml-6">
            <Text variant="sm" className="font-bold">
              {donate.heading}
            </Text>
            <Button rounded size="sm" dark color="black" to={donate.button.url}>
              {donate.button.text}
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {};

export default Footer;
