import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          heading: descriptor1
          company: descriptor0
          privacy: link0 {
            url
            text
          }
          terms: link1 {
            url
            text
          }
          headerLinks {
            ... on Craft_headerLinks_link_BlockType {
              uid
              headLink {
                url
                text
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                }
              }
            }
          }
          phone: link4 {
            url
            text
          }
          email: link5 {
            url
            text
          }
          social {
            ... on Craft_social_account_BlockType {
              uid
              platform
              accounturl
            }
          }
          # donate info
          heading0
          link2 {
            url
            text
          }
          # embeds
          embeds {
            ... on Craft_embeds_embed_BlockType {
              uid
              html
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const {
    heading,
    company,
    privacy,
    terms,
    headerLinks,
    phone,
    email,
    social,
    heading0,
    link2,
    embeds,
  } = globalSet;
  return {
    heading,
    company,
    privacy,
    terms,
    headerLinks,
    donate: {
      heading: heading0,
      button: {
        url: link2.url,
        text: link2.text,
      },
    },
    phone,
    email,
    social,
    embeds,
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
